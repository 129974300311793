import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const AboutPage = () => {
    return (
        <Layout pageTitle="O Nas">
            <p className="textBlock">
                <strong className="font-bold">„Dobrą Markę”</strong> – profesjonalną szwalnię tętniącą życiem, kreatywnością i pasją pracujących w niej szwaczek - rozwijamy od 2018 roku. Nazwa firmy jest naszą wizytówką.
            </p>
            <p className="textBlock">
            <strong className="font-bold"> Dobro </strong> - otwarte na potrzeby człowieka, wpisaliśmy w misję społeczną firmy. Rodzic kupujący nasze bawełniane tekstylia dla niemowląt, kontrahent powierzający nam realizację kluczowego zlecenia, pracownik proszący o dopasowanie warunków zatrudnienia do jego sytuacji życiowej – zawsze będą dla nas ważne.
            </p>
            <p className="textBlock">
            <strong className="font-bold"> Marka</strong>  – firmuje wysoką jakość naszych produktów i usług. Składają się na nią m.in. dokładność, rzetelność i terminowość realizacji zleceń. Wpisaliśmy w nią także wszechstronność, kreatywność i indywidualne podejście do każdego zamówienia. Profesjonalny, rozbudowany park maszyn, doświadczony i zgrany zespół szwaczek ułatwiają nam realizację różnorodnych zamówień. Doświadczeniem dzielimy się organizując m.in. profesjonalne szkolenia z zakresu kroju i szycia.
            </p>
            <p className="textBlock">
                Firma nieustannie się rozwija, inwestuje w rozwój własny i pracowników, zmienia na lepsze życie lokalnej społeczności.
            </p>
            <p className="textBlock">
                Spółdzielnię Socjalną „Dobra Marka” założyli: <i>Gmina Karlino</i> oraz <i>Stowarzyszenie na Rzecz Rozwoju Domacyńskiego Ośrodka Spotkań.</i>
            </p>
            <p className="text-center">
                Zapraszamy do kontaktu <Link className="font-medium" to="/contact"><strong>Zespół Spółdzielni Socjalnej „Dobra Marka”</strong></Link>
            </p>
        </Layout>
    )
}

export default AboutPage